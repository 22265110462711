import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';

type IContactProps = {};

const Contact: React.FC<IContactProps> = ({}) => {
  const randNum = () => Math.floor(Math.random() * 256);
  const [color, setColor] = useState(
    `rgb(${randNum()},${randNum()},${randNum()})`
  );
  const [isHover, setIsHover] = useState(false);
  const [email, setEmail] = useState('hello@gordo.design');

  const changeColor = () => {
    setColor(`rgb(${randNum()},${randNum()},${randNum()})`);
  };

  useEffect(() => {
    const colorTimer = setInterval(changeColor, 125);
    return () => {
      clearInterval(colorTimer);
    };
  }, []);
  const {setIsSideBarShown} = usePageContext();

  useEffect(() => {
    setIsSideBarShown(false);
    return () => {};
  }, []);
  return (
    <article className="relative md:pl-20">
      <SEO title="Contact | Gordo Labs" />

      <section
        className="md:pl-10 pt-10 md:mt-0 h-full md:pr-40 md:flex md:items-center
    sm:p-3 block py-6 justify-start min-h-screen">
        <div className={'px-6 max-w-2xl'}>
          <h2 className="font-gordo py-6 tracking-wider">Hey! 👋</h2>
          <p className="font-charisma font-light pb-4 tracking-wide">
            What I love to do is solve problems and build products and
            experiences that somehow could make your life better or can provide
            remarkable experiences. I come from a creative background and love
            understanding and developing state-of-the-art technology.
          </p>
          <p className="font-charisma font-light pb-4 tracking-wide">
            You can count on me as a generalist with a creative background who
            has extended experience building tech products and understands all
            the steps of how a product is built. From idea to production.
          </p>
          <p className="font-charisma font-light pb-4 tracking-wide">
            If you enjoy the same stuff as me, follow or open conversation:
          </p>
          <button
            onClick={(e) => {
              copy(email);
              setEmail('copied!');
              setTimeout(() => setEmail('hello@gordo.design'), 1000);
            }}
            onMouseEnter={() => {
              setIsHover(!isHover);
            }}
            onMouseLeave={() => {
              setIsHover(!isHover);
            }}
            style={{
              color: isHover ? color : 'grey',
              borderColor: isHover ? color : 'rgba(237, 242, 247)',
            }}
            className="mt-3 text-base md:text-base border-2 rounded-xl font-charisma py-3 px-5
              hover:border-white shadow">
            {email}
          </button>
        </div>
      </section>
      <footer
        className={
          'fixed w-full bottom-0 right-0 px-5 pb-5 flex gap-4 items-center justify-between'
        }>

          
        <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
          © Gordo-Labs
        </p>
        <div className='flex gap-2'>
                <a className={"cursor-pointer flex items-center h-4 opacity-50 hover:opacity-100 transition-all"} target="_blank" href={"https://github.com/gordo-labs"}>
          <img height={15} width={15} src='/images/github.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://twitter.com/gordo_design"}>
          <img className='' height={15} width={15} src='/images/x.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://warpcast.com/gordo"}>
          <img className='' height={15} width={15} src='/images/farcaster.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://discord.gg/XMbCd8bgNQ"}>
          <img className='' height={15} width={15} src='/images/discord.svg'/>
        </a>
        <a href="/cv">
          <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
            CV
          </p>
        </a>
        </div>
      </footer>
    </article>
  );
};

Contact.defaultProps = {};

export default Contact;
